<template>
    <div class="keepCleanInfo">
        <!--        导航-->
        <div>
            <rx-nav-bar title="配货完成信息"></rx-nav-bar>
        </div>
        <div class="cleanTitle">配货申清</div>
        <div class="applyDetail"   v-for="(item ,index) in applyList" :key="index">
<!--            地址信息-->
            <div class="addressInfo"> {{item.roomDetailedAddress ? item.roomDetailedAddress : '暂无'}}</div>
<!--            分割线-->
            <div class="rule"></div>
<!--            详情-->
            <div class="divOne">
                <div class="divThree">
                    <p>
                        <span class="spanOne">申请人: </span>
                        <span class="spanTwo"> {{ item.applicationGlobaluserName ? item.applicationGlobaluserName : '暂无'  }}</span>
                    </p>
                    <p>
                        <span class="spanOne">紧急程度: </span>
                        <span class="spanThree">{{item.distributionStatus ? item.distributionStatus : '暂无'}}</span>
                    </p>
                </div>
                <div class="divFour">
                    <p>
                        <span class="spanOne">联系电话: </span>
                        <span class="spanTwo"> {{ item.applicationGlobaluserMoblile ? item.applicationGlobaluserMoblile : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">处理时间: </span>
                        <span class="spanThree"> {{item.appointmentTime ? item.appointmentTime.substring(0,10) : '暂无'}}</span>
                    </p>

                </div>
            </div>
            <div class="divTwo">
                <p>
                    <span class="spanOne">备注: </span>
                    <span class="spanTwo">{{ item.afterRentDesprition ? item.afterRentDesprition : '暂无'}}</span>
                </p>
            </div>
        </div>
<!--        保洁信息-->
        <div class="cleanTitle">物品清单</div>
        <div class="keepCleanPerson" >
            <div class="divOneA" v-for="(items,index) in tableList" :key="index">
                <div class="divThree" >
                    <p>
                        <span class="spanOne">位置: </span>
                        <span class="spanTwo">{{ items.regionalLocationName ? items.regionalLocationName : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">类型: </span>
                        <span class="spanThree"> {{items.goodsAllocationClassName ? items.goodsAllocationClassName : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">物品名称:  </span>
                        <span class="spanTwo">{{ items.itemName ? items.itemName : '暂无'}}</span>
                    </p>

                </div>
                <div class="divFour">
                    <p>
                        <span class="spanOne">品牌:  </span>
                        <span class="spanTwo">{{ items.brand ? items.brand : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">供应商:  </span>
                        <span class="spanTwo">{{ items.supplierName ? items.supplierName : '暂无'}}</span>
                    </p>
                </div>
            </div>
        </div>
        <!--        配货派单-->
        <div class="cleanTitle">配货派单</div>
        <div class="keepCleanPerson" >
            <div class="divOneA" v-for="(itemss,index) in applyMessage" :key="index">
                <div class="divThree">
                    <p>
                        <span class="spanOne">配货人: </span>
                        <span class="spanTwo">{{ itemss.userName ? itemss.userName : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">承担方: </span>
                        <span class="spanThree"> {{itemss.costBear ? itemss.costBear : '暂无'}}</span>
                    </p>
                    <p style="width: 8rem;">
                        <span class="spanOne">配货描述:  </span>
                        <span class="spanTwo">{{ itemss.distributionDescription ? itemss.distributionDescription : '暂无'}}</span>
                    </p>
                </div>
                <div class="divFour">
                    <p>
                        <span class="spanOne">配货时间:  </span>
                        <span class="spanTwo">{{ itemss.appointmentTime ? itemss.appointmentTime : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">物品总计:  </span>
                        <span class="spanTwo">{{ itemss.totalFee ? itemss.totalFee : '暂无'}}</span>
                    </p>
                </div>
            </div>
        </div>

<!--        完成费用 -->
        <div class="assume">
            <div :class="finalCost==''?grayDot : redDot"></div>
            <span class="spanOne">配送费用</span>
            <span class="vertiacl">|</span>
            <span >￥</span>
            <input class="inputText" type="number" v-model="finalCost">
            <div class="spanTwo">元</div>
        </div>
<!--        完成 时间-->
        <div class="assume"  @click="chooseTime">
            <div :class="finishTime==''?grayDot : redDot"></div>
            <span class="spanOne">完成时间</span>
            <span class="vertiacl">|</span>
<!--            <van-cell  class="inputText" :value="overDate" v-model="overDate" inputText/>-->
            <div class="inputText">{{finishTime}}</div>
            <img class="timeImg" src="../../../assets/images/calendar.png" />
<!--            <van-calendar v-model="overShow" @confirm="onConfirm" />-->
        </div>
        <div>
            <van-popup v-model="isCheckInDateShow" position="bottom">
                <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" item-height="35px" @confirm="checkInDateConfirm" @cancel="checkInDateCancel"/>
            </van-popup>
        </div>
        <div class="feedback">
            <div class="feedbackText" >
                <span class="spanOne">处理结果:</span>
                <span >(非必填)</span>
            </div>
            <textarea class="feedbackTextarea" placeholder="输入取消原因" v-model="dealResult" ></textarea>

            <div class="uploadDiv">
                <van-uploader v-model="fileList" accept="image/*" image-fit="contain"  :max-count="10" :after-read="afterReadTest" multiple  :before-delete="deleteImage">
                    <div class="btn">
                        <!--                       preview-size="95"-->
                        <van-icon name="plus" size="30px" color="#dddddd"/>
                    </div>
                </van-uploader>
            </div>

        </div>
<!--        提交按钮-->
        <button :class="!finalCost||!finishTime ? graySaveBtn : redsaveBtn"
                    :disabled="!finalCost||!finishTime" @click="saveFinishCompleted">
            提交
        </button>

<!--        ====================================完成时间的弹窗==============================-->

        <!--拨打电话弹窗-->

    </div>
</template>

<script>
    import { NavBar,Calendar,cell,uploader,button,icon,picker,popup,DatetimePicker } from 'vant';
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {globaluserId,getStaffId, responseUtil} from "../../../libs/rongxunUtil";
    import {base64Upload,deliveryCompletedHeadList,saveFinishCompleted} from "../../../getData/getData";
    export default {
        components: {
            [NavBar .name]:NavBar,
            [Calendar .name]:Calendar,
            [cell .name]:cell,
            [uploader .name]:uploader,
             [button .name]:button,
            rxNavBar,
            [icon .name]:icon,
            [picker .name]:picker,
            [popup .name]:popup,
            [DatetimePicker .name]:DatetimePicker
        },
        data() {
            return {
                roleType: '1',  //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
                //保洁初始化数据信息
                applyDetailOrders : {},
                //保洁承担方弹窗控制
                showPickerBearer : false,
                //保洁承担方初始化数据
                bearerList : [],
                costBear : '',
                //完成时间选择弹窗控制
                isCheckInDateShow : false,
                //完成费用
                finalCost:'',
                //完成时间
                currentDate : new Date(),
                finishTime : '',
                //反馈
                dealResult : '',
                //灰点显示
                grayDot : 'grayDot',
                // 红点显示
                redDot : 'redDot',
                //提交按钮
                graySaveBtn : 'graySaveBtn',
                redsaveBtn : 'redsaveBtn',
            //    上传图片的列表
                picList : [{}],
                fileList:[],

                //接口数据
                did:'',//配货id
                applyList:[],
                tableList:[],
                applyMessage:[],
                time:''//完成时间 接口使用
            }
        },
        created() {
            this.did= this.$route.query.id
            this.roleType= this.$route.query.roleType //登录人是否为装修负责人标识， 是 0   否 1
            this.distributionCompletedInit()
        },
        methods : {
            //配货申请初始化
            distributionCompletedInit(){
                let that = this
                let initData = {
                    // user_id :getStaffId(),
                   id:that.did
                }
                if(that.roleType == '1'){
                    initData.user_id = getStaffId()
                }else if(that.roleType == '0'){
                    initData.user_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
                }
                deliveryCompletedHeadList(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                      if (response.data.code==0){
                          that.applyList=response.data.data.headList;
                          that.tableList=response.data.data.tableList;
                          that.applyMessage=response.data.data.item
                          that.finalCost=response.data.data.item[0].distributionFee
                      }else if (response.data.code!=0){
                          responseUtil.alertMsg(that,response.code)
                      } else {
                          responseUtil.alertMsg(that,response.code)
                      }
                    })
                })
            },

            //配货完成保存接口
            saveFinishCompleted(){
                let that = this
                let initData = {
                    id:that.did,
                    finalCost:that.finalCost,
                    handoverDate:that.time,
                    remarks:that.dealResult,
                    picList:that.picList
                }
                if(that.roleType == '1'){
                    initData.user_id = getStaffId()
                }else if(that.roleType == '0'){
                    initData.user_id = '1'  //如果登录人是装修负责人（非员工），则使用固定值1
                }
                saveFinishCompleted(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        if (response.data.code==0) {
                            responseUtil.alertMsg(that,response.data.msg)
                            that.$router.go(-1)
                        }else if(response.data.code!=0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else {
                            responseUtil.alertMsg(that,response.data.msg)
                        }

                    })
                })
            },
            //图片删除时处理
            deleteImage(file){
                for (let i=0;i<this.picList.length;i++){
                    if (this.picList[i].path==file.path){
                        this.picList.splice(i,1);
                    }
                }
                return true
            },

            //图片多选上传
            afterReadTest(file){
                // this.loadingFlag = true;
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        this.afterRead(fileDetail,i,fileLength);
                    }
                }else{
                    this.afterRead(file);
                }
            },

            afterRead(file,i,fileLength) {
                let that = this
                let initData = {
                    base64 : file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        file.path = response.data.data.path
                        that.picList.push({"path":response.data.data.path})
                        console.log(that.picList)
                        if(i == fileLength){
                            that.loadingFlag = false
                        }
                    })
                })
            },

            //日期选择组件 点击确认键后执行方法
            checkInDateConfirm(value){
                var date = new Date(value)
                this.finishTime = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
                this.time = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+'T'+date.getHours()+':'+date.getMinutes()+':'+'00'+'.000Z'
                this.isCheckInDateShow = false
            },

            //日期选择组件 点击取消键后执行方法
            checkInDateCancel(){
                this.isCheckInDateShow = false
            },

            chooseTime(){
                this.isCheckInDateShow = !this.isCheckInDateShow
            },

        }
    }
</script>

<style lang="less" scoped >
    *{
        margin: 0;
        padding: 0;
    }
    .keepCleanInfo{
        padding-bottom: 7px;
        overflow: hidden;
    }
    /*公共的p和span标签*/
    .spanOne{
        font-size: 14px;
        font-weight: bold;
        color: black;
        width: 20%;
    }
    .spanTwo{
        font-size: 14px;
        color: #9a9a9a;
    }
    .spanThree{
        font-size: 13px;
        color:#FF5D3B ;
    }
    /*公共的竖线*/
    .vertiacl{
        margin: 0 10px;
        font-size: 14px;
        color: #e6e6e6;
    }
    /*公共的点*/
    .grayDot{
        border: 3px solid #9a9a9a;
        margin: 0 10px 0 15px;
        border-radius: 50%;
    }
    /*公共的小圆点*/
    .redDot{
        border: 3px solid #FF5D3B;
        margin: 0 10px 0 15px;
        border-radius: 50%;
    }
    /*公共的input*/
    .inputText{
        height:55px;
        border: none;
        width: 50.5%;
        line-height: 55px;
    }
    .timeImg{
        height: 26px;
        width: 26px;
        margin-left: 7px;
    }
    .divOne,.divTwo{
        width: 92%;
        margin: 0 15px;
    }
    .divOne{
        display: flex;
        .divThree,.divFour{
            width: 50%;
        }

    }
    .divOneA{
        width: 100%;
        display: flex;
        .divThree,.divFour{
            width: 50%;
        }
    }
    //申请详情
    .cleanTitle{
        width: 92%;
        margin: 0 auto 5PX;
        font-size: 12px;
        color: #FF5D3B;
    }
/*    申请详情的主体*/
    .applyDetail{
        width: 92%;
        margin: 0 auto 15PX;
        background-color: #ffffff;
        border-radius: 8px;
        padding-bottom: 15px;
        line-height: 24px;
        padding-top: 15px;
        .addressInfo{
            font-size: 15px;
            margin: 0 15px;
            font-weight: bold;
        }
        .rule{
            border: 0.5px solid #f7f7f7;
            margin: 15px 0;
        }
    }
    /*保洁员信息*/
    .keepCleanPerson{
        width: 84%;
        margin: 0 auto 24px;
        background-color: #ffffff;
        padding: 15px;
        line-height: 24px;
        border-radius: 8px;

    }
/*    承担方*/
    .assume{
        width: 92%;
        margin: 0 auto 15px;
        background-color: #ffffff;
        display: flex;
        height: 50px;
        line-height: 24px;
        align-items: center;
        font-size: 14px;
        border-radius: 8px;
        overflow: hidden;

    }
/*    承担方的下拉菜单*/
/*    .selectStyle{*/
/*        width: 69%;*/
/*        font-size: 14px;*/
/*        margin-top: -3px;*/
/*        border: none;*/
/*        background-color: white;*/
/*        height: 47px;*/
/*    }*/
/*    处理反馈*/
    .feedback{
        width: 92%;
        margin: 18px auto 30px;
        /*height: 150px;*/
        border-radius: 8px;
        background-color: #fff;
        padding-top: 6px;
        padding-bottom: 6px;

        /*text-align: center;*/
        /*处理反馈DIV*/
        .feedbackText{
            /*margin: 18px 15px 15px;*/
            margin: 0 15px 10px;
            font-size: 13px;
            color: #9d9d9d;

        }
        .feedbackTextarea{
            width: 83%;
            display: block;
            margin: 0 auto;
            height:60px;
            padding: 15px;
            /*color: #e6e6e6;*/
            background-color: #f7f7f7 ;
            border-radius: 8px;
            border: none;
            font-size: 14px;
            resize: none;
        }

    }
    /*处理反馈的文本域*/
    textarea::placeholder{
        font-size: 13px;
        color: #d5d5d5;
    }
    input::placeholder{
        color:#d5d5d5 ;
    }
    .uploadDiv{
        width: 95%;
        /*margin: 15px;*/
        border-radius: 8px ;
        /*margin-left: 22px;*/
        margin: 5px auto;
        .btn{
            background-color:#f7f7f7;
            width:90px ;
            height:75px;
            border-radius: 8px;
            text-align: center;
            padding-top: 15px;
            margin-top: 10px;
            margin-left: 7px;
            i {
                margin-top: 16px;
            }
        }
    }

    .preview-cover {
        /*position: absolute;*/
        box-sizing: border-box;
        bottom: 0;
        width: 100%;
        /*padding: 4px;*/
        margin-left: 50px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background: rgba(0, 0, 0, 0.3);
    }

/*    提交按钮*/
    .graySaveBtn,.redsaveBtn{
        width: 92%;
        display: block;
        margin: 0 auto 42px;
        height: 50px;
        border-radius: 8px;
        border: none;
        font-size: 14px;
        color: white;
    }
    /*按钮默认灰色*/
    .graySaveBtn{
        background-color:rgba(184, 184, 184, 0.2);
    }
    /*按钮满足条件变成蓝色*/
    .redsaveBtn{
        background-image: linear-gradient(to right,#FFC274,#FF5D3B);
    }
    //费用承担方
    .downMenu{
        width: 60% ;
        height: 30px;
        display: flex;
        align-items: center;
        .downMenuValue{
            width: 95%;
            font-size: 14px;
        }
        .arrows{
            width: 0;
            height: 0;
            border: 3.5px solid;
            border-color: black transparent transparent transparent;
            margin-top: 5px;
        }
    }
    .date-dropDownList{
        margin-left: 15px;
        width: 92%;
        margin-right: 10px;
        position: absolute;
        border-radius: 8px;
        bottom: 0;
        z-index: 99;
        top: 700px;
    }
</style>
